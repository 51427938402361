import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { ReactComponent as AngleRightSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/angle-right.svg"

import Link from "../link"

const Container = styled.nav`
  background-color: ${(props) => props.theme.colorGreyLightest};
`
const Inner = styled.div`
  ${(props) => props.theme.gridContainer()};
`
const List = styled.ol`
  list-style: none;
  white-space: nowrap;
  overflow-x: auto;

  li {
    display: inline;
    font-size: ${rem(14)};
    color: ${(props) => props.theme.colorBlueDark};

    a {
      display: inline-block;
      outline-offset: -0.4em;
      padding-top: ${rem(20)};
      padding-bottom: ${rem(20)};
    }

    svg {
      width: ${rem(24)};
      height: ${rem(16)};
    }
  }
`

const Breadcrumbs = ({ allPages, currentPage }) => {
  const generateCrumbs = (pageId) => {
    const page = allPages.edges.find(({ node }) => node.wordpressId === pageId)

    if (page) {
      crumbs.push(page.node)
      if (page.node.wordpressParent)
        return generateCrumbs(page.node.wordpressParent)
    }

    return null
  }

  const crumbs = []
  if (currentPage.wordpressParent !== 0) {
    generateCrumbs(currentPage.wordpressParent)
  }

  return (
    <Container>
      <Inner>
        <List>
          <li itemScope itemType={`http://data-vocabulary.org/Breadcrumb`}>
            <Link to={`/`} itemProp="url">
              <span itemProp="title">Start</span>
            </Link>
            <AngleRightSvg />
          </li>

          {crumbs.reverse().map((crumb) => (
            <li
              key={crumb.id}
              itemScope
              itemType={`http://data-vocabulary.org/Breadcrumb`}
            >
              <Link to={crumb.path}>
                <span itemProp="title">{crumb.title}</span>
              </Link>

              <AngleRightSvg />
            </li>
          ))}

          <li itemScope itemType={`http://data-vocabulary.org/Breadcrumb`}>
            <span itemProp="title">{currentPage.title}</span>
          </li>
        </List>
      </Inner>
    </Container>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  allPages: PropTypes.object,
  currentPage: PropTypes.object,
}
