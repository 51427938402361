import React, { useState } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { rem } from "polished"
import styled from "styled-components"

import Lightbox from "./lightbox"
import Link from "../link"

const Container = styled.section`
  padding: ${rem(30)} 0;
`

const Images = styled.div`
  ${(props) => props.theme.gridContainer()};
  ${(props) => props.theme.gridGrid()};
  ${(props) => props.theme.gridGridGutterX()};
  ${(props) => props.theme.gridGridGutterY()};

  > div {
    ${(props) => props.theme.gridCell(4)};

    @media ${(props) => props.theme.mediumDown} {
      ${(props) => props.theme.gridCell(6)};
    }

    @media ${(props) => props.theme.xsmallDown} {
      ${(props) => props.theme.gridCell(12)};
    }
  }
`

const Figure = styled.figure``

const Image = styled.div`
  > a {
    display: block;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    > .gatsby-image-wrapper > div {
      padding-bottom: 70% !important;
    }
  }
`

const FigureCaption = styled.figcaption`
  padding: ${rem(10)} 0;
  font-size: ${rem(14)};
  font-style: italic;
`

const LayoutGallery = ({ showCaptions, images }) => {
  const [initialImageId, setInitialImageId] = useState(null)

  const imageClick = (e) => {
    e.preventDefault()
    setInitialImageId(e.currentTarget.dataset.id)
  }

  return (
    <Container>
      <h2 className="is-vhidden">Galleri</h2>

      {initialImageId && (
        <Lightbox
          images={images}
          initialImageId={initialImageId}
          onRequestClose={() => setInitialImageId(null)}
          showCaptions={showCaptions}
        />
      )}

      <Images>
        {images.map((image, index) => (
          <div key={image.id}>
            <Figure>
              <Image>
                <Link
                  to={image.localFile.childImageSharp.fluid.src}
                  internal={false}
                  target="_blank"
                  data-id={index}
                  onClick={imageClick}
                  title={image.alt_text || image.title}
                >
                  <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={image.altText}
                  />
                </Link>
              </Image>

              {showCaptions && (
                <FigureCaption
                  dangerouslySetInnerHTML={{
                    __html: image.caption,
                  }}
                />
              )}
            </Figure>
          </div>
        ))}
      </Images>
    </Container>
  )
}

LayoutGallery.propTypes = {
  showCaptions: PropTypes.bool,
  images: PropTypes.array,
}

export default LayoutGallery
