import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MetaWp from "../components/meta-wp"
import Layout from "../components/layout"
import Breadcrumbs from "../components/breadcrumbs"
import PageComponents from "../components/page-components"
import PageBlock from "../components/page-block"

const DefaultPageTemplate = ({ data }) => {
  const {
    wp,
    metaWp,
    allPages,
    allPosts,
    site: { siteMetadata },
    wordpressAcfOptions: { globalMeta },
  } = data

  const {
    children,
    acf: { hideContent },
  } = wp

  return (
    <Layout>
      <MetaWp {...metaWp} global={globalMeta} defaults={siteMetadata} />

      {hideContent && (
        <h1
          className={`is-vhidden`}
          dangerouslySetInnerHTML={{ __html: wp.title }}
        />
      )}

      {wp.path !== `/` && <Breadcrumbs allPages={allPages} currentPage={wp} />}

      {!hideContent && <PageBlock {...wp} />}

      <PageComponents
        wordpressId={wp.wordpressId}
        components={children}
        allPages={allPages}
        allPosts={allPosts}
      />
    </Layout>
  )
}

DefaultPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DefaultPageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...SiteMetaData
    }

    wordpressAcfOptions {
      ...GlobalMetaOptions
    }

    ...MetaWpPage

    ...AllPagesFragment
    ...AllPostsFragment

    wp: wordpressPage(id: { eq: $id }) {
      ...PageDefaultFragment
    }
  }
`
