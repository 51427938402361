import React from "react"
import PropTypes from "prop-types"

import Hero from "../hero"
import ContentGrid from "../content-grid"
import ButtonBlock from "../button-block"
import TextBlock from "../text-block"
import EmbeddedContent from "../embedded-content"
import FeatureList from "../feature-list"
import FileList from "../file-list"
import Images from "../images"

const PageComponents = ({ components, allPages, allPosts, wordpressId }) => {
  if (!components) {
    return null
  }

  return components.map((child) => {
    if (child.__typename === `WordPressAcf_hero`) {
      return <Hero key={child.id} {...child} />
    }
    if (child.__typename === `WordPressAcf_content_grid`) {
      return (
        <ContentGrid
          key={child.id}
          {...child}
          sort={child.sort ? child.sort : `published_asc`}
          wordpressId={wordpressId}
          allPages={allPages}
          allPosts={allPosts}
        />
      )
    }
    if (child.__typename === `WordPressAcf_text_block`) {
      return <TextBlock key={child.id} {...child} />
    }
    if (child.__typename === `WordPressAcf_button`) {
      return <ButtonBlock key={child.id} {...child} />
    }
    if (child.__typename === `WordPressAcf_embedded_content`) {
      return <EmbeddedContent key={child.id} {...child} />
    }
    if (child.__typename === `WordPressAcf_feature_list`) {
      return <FeatureList key={child.id} {...child} />
    }
    if (child.__typename === `WordPressAcf_file_list`) {
      return <FileList key={child.id} {...child} />
    }
    if (child.__typename === `WordPressAcf_images`) {
      return <Images key={child.id} {...child} />
    }

    return null
  })
}

PageComponents.propTypes = {
  components: PropTypes.array,
  wpId: PropTypes.number,
  allPages: PropTypes.object,
  allPosts: PropTypes.object,
  pageChildren: PropTypes.array,
}

export default PageComponents
