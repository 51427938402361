import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

import Button from "../button"
import SvgSprite from "../svg-sprite"

const Container = styled.section`
  ${(props) => props.theme.gridContainer()};

  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`

const ButtonBlock = ({ published, layout, icon, color, link }) => {
  if (!published) {
    return null
  }

  return (
    <Container center={layout === `center`}>
      <h2 className="is-vhidden">{link.title}</h2>

      <Button
        to={link.url}
        target={link.target}
        opts={`soft xl i-left c-${color} ${layout}`}
      >
        {icon && <SvgSprite id={icon} />}
        {link.title}
      </Button>
    </Container>
  )
}

ButtonBlock.propTypes = {
  published: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.object,
  layout: PropTypes.string,
}

export default ButtonBlock
