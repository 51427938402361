import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import { adjustColorName } from "../../utils/color"
import SvgSprite from "../svg-sprite"

const Container = styled.section`
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};
  color: ${(props) => props.theme.colorBlueDark};

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(30)};
    padding-bottom: ${rem(30)};
  }
`

const Inner = styled.div`
  ${(props) => props.theme.gridContainer()};
  ${(props) => props.theme.gridGrid()};
  ${(props) => props.theme.gridGridGutterX()};
  ${(props) =>
    props.textAlign === `center` &&
    css`
      justify-content: center;
    `}
`

const Content = styled.div.attrs({ className: `styled` })`
  ${(props) => props.theme.gridCell(7)};

  @media ${(props) => props.theme.mediumDown} {
    ${(props) => props.theme.gridCell(12)};
  }

  ${(props) =>
    props.textAlign === `center` &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.textAlign === `right` &&
    css`
      margin-left: auto;

      @media ${(props) => props.theme.mediumDown} {
        margin-top: ${rem(30)};
      }
    `}
`

const Aside = styled.aside`
  ${(props) => props.theme.gridCell(4)};

  @media ${(props) => props.theme.mediumDown} {
    ${(props) => props.theme.gridCell(12)};
  }

  ${(props) =>
    props.textAlign !== `right` &&
    css`
      margin-left: auto;

      @media ${(props) => props.theme.mediumDown} {
        padding-top: ${rem(20)};
      }
    `}

  ${(props) =>
    props.textAlign === `right` &&
    css`
      order: -1;
    `}
`

const AsideInner = styled.div`
  padding: ${rem(40)};
  border-radius: ${rem(5)};
  background-color: ${(props) =>
    (props.backgroundColor &&
      props.theme[adjustColorName(props.backgroundColor)]) ||
    props.theme.colorWhite};

  @media ${(props) => props.theme.smallDown} {
    padding: ${rem(20)};
  }
`

const AsideIcon = styled.figure`
  margin-bottom: ${rem(10)};
  text-align: center;

  svg {
    width: ${rem(48)};
    height: ${rem(48)};
  }
`

const AsideTitle = styled.h3.attrs({ className: `styled-h3` })`
  margin-bottom: ${rem(10)};
  text-align: center;
`

const AsideContent = styled.div`
  line-height: 1.5;
`

const TextBlock = ({
  published,
  text,
  textAlign,
  showAside,
  aside,
  showAsideImage,
  asideImage,
}) => {
  if (!published) {
    return null
  }

  return (
    <Container>
      <Inner textAlign={textAlign}>
        <Content
          textAlign={textAlign}
          dangerouslySetInnerHTML={{ __html: text }}
        />

        {showAside && (
          <Aside textAlign={textAlign}>
            <AsideInner backgroundColor={aside.backgroundColor}>
              <AsideIcon>
                <SvgSprite id={aside.icon} />
              </AsideIcon>

              <AsideTitle dangerouslySetInnerHTML={{ __html: aside.title }} />

              <AsideContent dangerouslySetInnerHTML={{ __html: aside.text }} />
            </AsideInner>
          </Aside>
        )}

        {showAsideImage && (
          <Aside textAlign={textAlign}>
            <Img
              fluid={asideImage.localFile.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={asideImage.altText}
            />
          </Aside>
        )}
      </Inner>
    </Container>
  )
}

TextBlock.propTypes = {
  published: PropTypes.bool.isRequired,
  text: PropTypes.string,
  textAlign: PropTypes.string,
  showAside: PropTypes.bool,
  aside: PropTypes.object,
  showAsideImage: PropTypes.bool,
  asideImage: PropTypes.object,
}

export default TextBlock
