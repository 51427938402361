import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

import { adjustColorName } from "../../utils/color"

const Container = styled.section`
  color: ${(props) => props.theme.colorBlueDark};
  background-color: ${(props) =>
    props.theme[adjustColorName(props.backgroundColor)] ||
    props.theme.colorWhite};

  ${(props) =>
    props.hasTitle &&
    css`
      padding-top: ${rem(30)};
      padding-bottom: ${rem(30)};

      @media ${(props) => props.theme.mediumDown} {
        padding-top: ${rem(20)};
        padding-bottom: ${rem(20)};
      }
    `}

  h2 {
    margin-bottom: ${rem(15)};
  }
`

const Inner = styled.div`
  ${(props) => props.theme.gridContainer()};
`

const CodeContainer = styled.div``

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
  }
`

const EmbeddedContent = ({
  published,
  type,
  title,
  code,
  oembedUrl,
  backgroundColor,
}) => {
  if (!published) {
    return null
  }

  if (type === `code`) {
    return (
      <Container hasTitle={!!title} backgroundColor={backgroundColor}>
        <Inner>
          {title && (
            <h2
              className={`styled-h3`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}

          <CodeContainer
            dangerouslySetInnerHTML={{
              __html: code,
            }}
          />
        </Inner>
      </Container>
    )
  }

  if (type === `oembed_link`) {
    return (
      <Container hasTitle={!!title} backgroundColor={backgroundColor}>
        <Inner>
          {title && (
            <h2
              className={`styled-h3`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}

          <EmbedContainer dangerouslySetInnerHTML={{ __html: oembedUrl }} />
        </Inner>
      </Container>
    )
  }

  return null
}

EmbeddedContent.propTypes = {
  type: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  title: PropTypes.string,
  code: PropTypes.string,
  oembedUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default EmbeddedContent
