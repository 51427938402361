import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import styled from "styled-components"

import Link from "../link"
import SvgSprite from "../svg-sprite"

const Container = styled.section`
  ${(props) => props.theme.gridContainer()};

  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};
  color: ${(props) => props.theme.colorBlueDark};

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(30)};
    padding-bottom: ${rem(30)};
  }
`

const Inner = styled.div`
  border-radius: ${rem(5)};
  background-color: ${(props) => props.theme.colorGreyLightest};
  padding: ${rem(40)};

  @media ${(props) => props.theme.smallDown} {
    padding: ${rem(20)};
  }

  h2 {
    margin-bottom: ${rem(10)};
  }
`

const Files = styled.ul`
  padding-top: ${rem(20)};
  margin-top: ${rem(20)};
  border-top: solid 1px ${(props) => props.theme.colorGreyLighter};
`

const File = styled.li`
  margin-bottom: ${rem(15)};

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: flex;

    &:hover {
      color: ${(props) => props.theme.colorBlue};
    }

    svg {
      width: ${rem(48)};
      height: ${rem(48)};
      margin-right: ${rem(15)};
    }

    strong {
      display: block;
    }

    small {
      font-size: ${rem(14)};
      color: ${(props) => props.theme.colorGrey};
    }
  }
`

const FileList = ({ id, published, title, description, files }) => {
  if (!published) {
    return null
  }

  files = files && files.filter((f) => f.file && f.file.localFile)

  return (
    <Container>
      <Inner>
        <h2 className="styled-h3" dangerouslySetInnerHTML={{ __html: title }} />

        <div
          className="styled"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {files && !!files.length && (
          <Files>
            {files.map((file, i) => (
              <File key={i}>
                <Link to={file.file.localFile.url}>
                  <SvgSprite id="downloaded-file" />

                  <div>
                    <strong>{file.title}</strong>

                    <small>({file.file.localFile.extension})</small>
                  </div>
                </Link>
              </File>
            ))}
          </Files>
        )}
      </Inner>
    </Container>
  )
}

FileList.propTypes = {
  id: PropTypes.string,
  published: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.array,
}

export default FileList
