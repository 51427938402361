import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import styled from "styled-components"

import Link from "../link"
import SvgSprite from "../svg-sprite"
import { ReactComponent as ArrowSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/arrow-right.svg"

const Container = styled.section`
  padding: ${rem(30)} 0;
  color: ${(props) => props.theme.colorBlueDark};
`

const Header = styled.header`
  ${(props) => props.theme.gridContainer()};

  margin-bottom: ${rem(30)};
`

const Features = styled.div`
  ${(props) => props.theme.gridContainer()};
  ${(props) => props.theme.gridGrid()};
  ${(props) => props.theme.gridGridGutterX()};
  ${(props) => props.theme.gridGridGutterY(40)};

  @media ${(props) => props.theme.smallDown} {
    ${(props) => props.theme.gridGridGutterY(30)};
  }

  > div {
    ${(props) => props.theme.gridCell(6)};

    @media ${(props) => props.theme.smallDown} {
      ${(props) => props.theme.gridCell(12)};
    }
  }
`

const Item = styled.div`
  display: flex;
`

const ItemIcon = styled.div`
  color: ${(props) => props.theme.colorBlue};
  margin-right: ${rem(20)};

  svg {
    width: ${rem(48)};
    height: ${rem(48)};
  }
`

const ItemContent = styled.div`
  > h3 {
    margin-bottom: ${rem(15)};
  }

  > div {
    line-height: 1.6;

    > * + * {
      margin-top: 0.625em;
    }
  }

  > a {
    display: block;
    color: ${(props) => props.theme.colorBlue};
    font-weight: 600;
    margin-top: ${rem(15)};

    &:hover {
      svg {
        transform: translateX(${rem(4)});
      }
    }

    svg {
      width: ${rem(22)};
      height: ${rem(22)};
      transition: transform 0.2s ease-in-out;
    }
  }
`

const FeatureList = ({ id, published, title, showIcons, items }) => {
  if (!published) {
    return null
  }

  return (
    <Container>
      <Header>
        <h2
          className={`styled-h4`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Header>

      <Features>
        {items.map((item, index) => (
          <div key={`FeaturedItem${id}${index}`}>
            <Item>
              {showIcons && (
                <ItemIcon>
                  <SvgSprite id={item.icon} />
                </ItemIcon>
              )}

              <ItemContent>
                <h3
                  className={`styled-h3`}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />

                <div dangerouslySetInnerHTML={{ __html: item.description }} />

                <Link to={item.link.url} target={item.link.target}>
                  <span dangerouslySetInnerHTML={{ __html: item.link.title }} />

                  <ArrowSvg />
                </Link>
              </ItemContent>
            </Item>
          </div>
        ))}
      </Features>
    </Container>
  )
}

FeatureList.propTypes = {
  published: PropTypes.bool.isRequired,
  showIcons: PropTypes.bool.isRequired,
  title: PropTypes.string,
  items: PropTypes.array,
  id: PropTypes.string,
}

export default FeatureList
