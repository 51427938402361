import React from "react"
import ReactModal from "react-modal"
import { RemoveScroll } from "react-remove-scroll"
import PropTypes from "prop-types"
import { rem, rgba } from "polished"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import { ReactComponent as CloseSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/close.svg"
import { ReactComponent as ArrowRightSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/arrow-right.svg"
import { ReactComponent as ArrowLeftSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/arrow-left.svg"

ReactModal.setAppElement(`body`)

const ReactModalStyled = styled(ReactModal)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${(props) => props.theme.ziImagesLightbox};
  top: 0;
  left: 0;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => rgba(props.theme.colorBlack, 0.8)};
`

const RemoveScrollStyled = styled(RemoveScroll)`
  height: 100%;
`

const Close = styled.div`
  position: absolute;
  z-index: 2;
  top: ${rem(15)};
  right: ${rem(15)};

  button {
    width: ${rem(40)};
    height: ${rem(40)};
    display: block;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 0.6;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ArrowBack = styled(ButtonBack)`
  margin-left: ${rem(32)};
  opacity: 0.8;

  @media ${(props) => props.theme.mediumUp} {
    margin: 0 ${rem(16)};
  }

  &:hover {
    opacity: 1;
  }

  svg {
    display: none;
    width: ${rem(40)};
    height: ${rem(40)};

    @media ${(props) => props.theme.mediumUp} {
      display: block;
    }
  }
`
const ArrowNext = styled(ButtonNext)`
  opacity: 0.8;

  @media ${(props) => props.theme.smallUp} {
    margin: 0 ${rem(16)};
  }

  &:hover {
    opacity: 1;
  }

  svg {
    display: none;
    width: ${rem(40)};
    height: ${rem(40)};

    @media ${(props) => props.theme.mediumUp} {
      display: block;
    }
  }
`

const Container = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
`

const Carousel = styled(CarouselProvider)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const SlideContainer = styled.div`
  width 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .carousel {
    height: 100%;
  }

  .carousel__inner-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SlideImage = styled(Img)`
  max-width: ${(props) => rem(props.maxWidth)};
`

const StyledSlide = styled(Slide)`
  .carousel__inner-slide {
    display: flex;
    flex-direction: column;
  }
`

const Dots = styled(DotGroup)`
  position: absolute;
  bottom: ${rem(15)};
  left: ${rem(60)};
  right: ${rem(60)};
  opacity: 0.8;
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(10)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
    rgba(0, 0, 0, 0) 100%
  );

  @media ${(props) => props.theme.mediumDown} {
    left: ${rem(5)};
    right: ${rem(5)};
  }

  button {
    width: ${rem(25)};
    height: ${rem(25)};
    display: inline-block;
    position: relative;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 0.6;
    }

    &::after {
      content: "";
      width: ${rem(10)};
      height: ${rem(10)};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: currentColor;
      border: 2px solid transparent;
    }

    &.carousel__dot--selected::after {
      background-color: transparent;
      border-color: currentColor;
    }
  }
`

const Caption = styled.figcaption`
  padding: ${rem(10)} 0;
  font-size: ${rem(14)};
  font-style: italic;
  width: 100%;
`

const Lightbox = ({ images, initialImageId, onRequestClose, showCaptions }) => {
  return (
    <ReactModalStyled
      isOpen={true}
      defaultStyles={{}}
      onRequestClose={onRequestClose}
      contentLabel="Galleri"
    >
      <Close>
        <button
          type="button"
          aria-label="Stäng"
          title="Stäng"
          onClick={() => onRequestClose()}
        >
          <CloseSvg />
        </button>
      </Close>

      <RemoveScrollStyled>
        <Container>
          <Carousel
            naturalSlideWidth={
              images[0].localFile.childImageSharp.original.width
            }
            naturalSlideHeight={
              images[0].localFile.childImageSharp.original.height
            }
            totalSlides={images.length}
            currentSlide={initialImageId}
          >
            <SlideContainer>
              <ArrowBack>
                <ArrowLeftSvg />
              </ArrowBack>

              <Slider>
                {images.map((image) => {
                  return (
                    <StyledSlide key={image.id}>
                      <SlideImage
                        fluid={image.localFile.childImageSharp.fluid}
                        maxWidth={
                          image.localFile.childImageSharp.original.width
                        }
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={image.altText}
                      />

                      {showCaptions && image.caption && (
                        <Caption
                          dangerouslySetInnerHTML={{ __html: image.caption }}
                        />
                      )}
                    </StyledSlide>
                  )
                })}
              </Slider>

              <ArrowNext>
                <ArrowRightSvg />
              </ArrowNext>
            </SlideContainer>

            <Dots />
          </Carousel>
        </Container>
      </RemoveScrollStyled>
    </ReactModalStyled>
  )
}

Lightbox.propTypes = {
  images: PropTypes.array,
  initialImageId: PropTypes.string,
  onRequestClose: PropTypes.func,
  showCaptions: PropTypes.bool,
}

export default Lightbox
