import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { rem } from "polished"
import styled from "styled-components"

const Container = styled.section`
  padding: ${rem(30)} 0;
`
const Inner = styled.figure`
  ${(props) => props.theme.gridContainer()};
`

const Caption = styled.figcaption`
  padding: ${rem(10)} 0;
  font-size: ${rem(14)};
  font-style: italic;
`

const LayoutSingle = ({ showCaptions, image }) => {
  if (!image) return null

  return (
    <Container>
      <h2 className="is-vhidden">Bild</h2>

      <Inner>
        <Img
          key={image.id}
          fluid={image.localFile.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={image.altText}
        />

        {showCaptions && (
          <Caption dangerouslySetInnerHTML={{ __html: image.caption }} />
        )}
      </Inner>
    </Container>
  )
}

LayoutSingle.propTypes = {
  showCaptions: PropTypes.bool,
  image: PropTypes.object,
}

export default LayoutSingle
