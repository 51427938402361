import React from "react"
import PropTypes from "prop-types"

import LayoutGallery from "./layout-gallery"
import LayoutSingle from "./layout-single"

const Images = ({ published, layout, ...props }) => {
  if (!published) {
    return null
  }

  if (layout === `gallery`) {
    return <LayoutGallery {...props} />
  }

  if (layout === `single`) {
    return <LayoutSingle {...props} />
  }

  return null
}

Images.propTypes = {
  published: PropTypes.bool.isRequired,
  layout: PropTypes.string,
}

export default Images
