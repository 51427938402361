import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import styled, { css } from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { ReactComponent as ArrowSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/arrow-right.svg"

import Link from "../link"

const Container = styled.section`
  position: relative;
  overflow: hidden;
  font-family: ${(props) => props.theme.ffPrimary};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colorPurpleDarker};
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.25;

  ${(props) =>
    props.backgroundSize === `contain` &&
    css`
      max-width: ${rem(1200)};
      left: 50%;
      transform: translateX(-50%);
    `}

  @media ${(props) => props.theme.largeUp} {
    opacity: 1;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: cover;
  }
`

const BackgroundOverlay = styled.div``

const Inner = styled.div`
  ${(props) => props.theme.gridContainer()};

  padding-top: ${rem(30)};
  padding-bottom: ${rem(30)};
  position: relative;
  z-index: 1;
  min-height: ${(props) =>
    props.height && props.height !== `auto` ? props.height : rem(400)};
  color: ${(props) => props.textColor || props.theme.colorWhite};
  text-align: ${(props) => props.textAlign || `left`};
  display: flex;
  align-items: center;

  /* IE11 fix */
  &::after {
    content: "";
    min-height: inherit;
    font-size: 0;
  }
  /* --- */

  > div {
    width: 100%;
  }
`

const Title = styled.h2`
  font-family: ${(props) => props.theme.ffTertiary};
  font-weight: 900;
  font-size: ${rem(24)};
  line-height: 1.1;
  word-break: break-word;

  @media ${(props) => props.theme.mediumUp} {
    font-size: ${rem(56)};
  }
`

const Content = styled.div`
  margin-top: ${rem(10)};
  margin-bottom: ${rem(30)};
  font-size: ${rem(18)};
  line-height: 1.5;
`

const CallToAction = styled.div`
  margin-top: ${rem(10)};

  a {
    font-size: ${rem(18)};

    svg {
      width: 2em;
      width: 1.4em;
      height: 1.4em;
      transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0s;
      fill: currentColor;
      vertical-align: middle;
      pointer-events: none;
    }

    span {
      position: relative;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 110%;
        width: 100%;
        height: 2px;
        background: currentColor;
        border-radius: 4px;
        transition: width 0.3s ease-in-out;
        transition-delay: 0.18s;
        pointer-events: none;
      }

      &::before {
        width: 100%;
        opacity: 0.5;
        right: 0%;
      }

      &::after {
        width: 0%;
        left: 0%;
      }
    }

    &:hover {
      span {
        &::before {
          width: 0%;
          transition-delay: 0s;
        }

        &::after {
          width: 100%;
          transition-delay: 0s;
        }
      }

      svg {
        transform: translateX(${rem(4)});
        transition-delay: 0.18s;
      }
    }
  }
`

const Hero = ({
  published,
  title,
  text,
  textAlign,
  textColor,
  height,
  backgroundImage,
  backgroundColor,
  backgroundSize,
  backgroundOpacity,
  link,
  linkColor,
}) => {
  if (!published) {
    return null
  }

  return (
    <Container backgroundColor={backgroundColor}>
      {backgroundImage && backgroundImage.localFile && (
        <React.Fragment>
          <Background
            backgroundSize={backgroundSize}
            style={{
              opacity:
                backgroundOpacity &&
                backgroundOpacity != 100 &&
                backgroundOpacity / 100,
            }}
          >
            <Img
              fluid={backgroundImage.localFile.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={backgroundImage.altText}
            />
          </Background>

          <BackgroundOverlay backgroundColor={backgroundColor} />
        </React.Fragment>
      )}

      <Inner textColor={textColor} textAlign={textAlign} height={height}>
        <div>
          <Title dangerouslySetInnerHTML={{ __html: title }} />

          <Content dangerouslySetInnerHTML={{ __html: text }} />

          {link.url && (
            <CallToAction>
              <Link
                to={link.url}
                target={link.target}
                style={{ color: linkColor }}
              >
                <span dangerouslySetInnerHTML={{ __html: link.title }} />

                <ArrowSvg />
              </Link>
            </CallToAction>
          )}
        </div>
      </Inner>
    </Container>
  )
}

Hero.propTypes = {
  published: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
  height: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundColor: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundOpacity: PropTypes.number,
  link: PropTypes.object,
  linkColor: PropTypes.string,
}

export default Hero
